<template>
  <b-clockpicker 
    ref="timepicker"
    v-model="time"
    locale="de-DE"
    :hour-format="hourFormat"
    editable
  >
    <div class="is-flex is-justify-content-space-between">
      <b-button
        label="Jetzt"
        type="is-primary"
        icon-left="clock"
        class="pl-3 pr-3"
        @click="selectCurrentTime"
      />
      <b-button
        label="Löschen"
        type="is-danger"
        icon-left="close"
        outlined
        class="pl-3 pr-3"
        @click="time = null"
      />
      <b-button
        label="Ok"
        type="is-primary"
        icon-left="check"
        class="pl-3 pr-3"
        @click="timepicker.toggle()"
      />
    </div>
  </b-clockpicker>
</template>
  
<script >
// libs
import { ref, computed, onMounted, watch } from '@vue/composition-api';
import Inputmask from 'inputmask';
import moment from 'moment-timezone';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  },
  values: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const timepicker = ref(null);
  const time = computed({
    get() {
      const timeString = props.values[props.field.id];
      if (!timeString) return '';
      const userTimeZone = moment.tz.guess();
      // Split the time string into hours, minutes, and possibly AM/PM
      const [time, period] = timeString.split(' ');
      let [hours, minutes] = time.split(':').map(Number);

      // Adjust hours for 12-hour format and AM/PM indication
      if (period === 'PM' && hours < 12) {
        hours += 12;
      } else if (period === 'AM' && hours === 12) {
        hours = 0;
      }

      // Create a moment object for the current date
      const currentDate = moment.tz(userTimeZone);

      // Set the hours and minutes
      currentDate.hours(hours);
      currentDate.minutes(minutes);
      return currentDate.toDate();
    },
    set(value) {
      const userTimeZone = moment.tz.guess();
      emit('update', {
        value: !value ? null : moment.tz(value, userTimeZone).format(hourFormat.value === '12' ? 'hh:mm A' : 'HH:mm')
      });
    }
  });
  const hourFormat = computed(() => {
    if (props.field.properties.basic.is24HourFormat) {
      return '24';
    } else {
      return '12';
    }
  });
  const updateMask = () => {
    const inputMaskInstance = new Inputmask({
      mask: hourFormat.value === '12' ? '99:99 aa' : '99:99'
    });
    const inputElement = timepicker.value?.$el.querySelector('input');
    if (inputElement) {
      inputMaskInstance.mask(inputElement);
    }
  };
  const selectCurrentTime = () => {
    time.value = new Date();
    timepicker.value?.toggle();
  };
  onMounted(() => {
    updateMask();
  });
  watch(() => hourFormat.value, newVal => {
    if (newVal) {
      updateMask();
    }
  }, {
    immediate: true
  });
  return {
    timepicker,
    time,
    hourFormat,
    selectCurrentTime
  };
};
export default __sfc_main;
</script>
  